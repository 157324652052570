<template>
  <div :style="{ padding: '1rem' }" role="main" data-id="meeting detail">
    <Detail :showButtons="true" :id="id" />
  </div>
</template>

<script>
//
import Detail from '../../../Panels/Detail'
//
import _get from 'lodash/get'
//
export default {
  components: {
    Detail
  },
  data() {
    return {
      id: parseInt(_get(this, ['$route', 'params', 'id'], '')),
      meetingTypes: []
    }
  },
  methods: {
    onUpdate() {}
  },
  mounted() {
    this.id = parseInt(_get(this, ['$route', 'params', 'id'], ''))
  }
}
</script>
